import React from 'react';
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import "../styles/404.scss";

const NotFoundPage = ({ data }) => (
  <>
    <Helmet>
      <title>Page Not Found | {data.site.siteMetadata.title}</title>
    </Helmet>
    <main>
      <h1>page not found ☹️</h1>
      <p>
        i couldn’t find what you were looking for but i’m sure you’ll find something somewhere 👀
      </p>
    </main>
  </>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
